import { parseISO, isBefore, differenceInMonths } from "date-fns";
import {
  handleError,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import {
  getRecentContacts,
} from "../lib/stateManagementFunctions";
import { isArray } from "underscore";
import {
  isUserDelegatedUser,
  isUserMaestroUser,
} from "../services/maestroFunctions";
import db from "../services/db";
import { immutablySortArray, isEmptyArray } from "./arrayFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isTypeString } from "../services/typeGuards";
import { getUserEmail } from "./userFunctions";
import { EXCLUDED_DOMAINS } from "../services/globalVariables";
import { getContactGroups } from "./settingsFunctions";
import { equalAfterTrimAndLowerCased, formatEmail, isValidEmail, lowerCaseAndTrimString } from "./stringFunctions";
import { getObjectEmail } from "./objectFunctions";


const SEARCH_CONTACT_LIMIT = 15;

export function sortContacts(contacts) {
  if (isEmptyArrayOrFalsey(contacts)) {
    return [];
  }

  const sortingFunction = (a, b) => {
    const key = "updated";
    const timeA = a[key] ? parseISO(a[key]) : null;
    const timeB = b[key] ? parseISO(b[key]) : null;

    if (isValidJSDate(timeA) && isValidJSDate(timeB)) {
      return isBefore(timeA, timeB) ? 1 : -1;
    } else if (
      isValidJSDate(timeA) &&
      Math.abs(differenceInMonths(timeA, new Date())) <= 12
    ) {
      return -1;
    } else if (
      isValidJSDate(timeB) &&
      Math.abs(differenceInMonths(timeB, new Date())) <= 12
    ) {
      return 1;
    } else if (a.name && !b.name) {
      return -1;
    } else if (!a.name && b.name) {
      return 1;
    } else if (a.email < b.email) {
      return -1;
    }

    return 0;
  };

  return immutablySortArray(contacts, (a, b) => sortingFunction(a, b));
}

export function getContactGroupMatches(currentUser, matchString) {
  if (!matchString) {
    return [];
  }
  const contactGroups = getContactGroups({ user: currentUser });
  let matches = [];
  let formattedMatchString = matchString.toLowerCase();
  contactGroups.forEach((n) => {
    if (n?.name?.toLowerCase().includes(formattedMatchString)) {
      matches = matches.concat(n);
    }
  });

  return matches;
}

export function createEmailString(emailList) {
  try {
    if (isEmptyArrayOrFalsey(emailList) || !isArray(emailList)) {
      return "";
    }
    const formattedList = immutablySortArray(emailList.filter(e => !!e)).map((e) => formatEmail(e));
    return formattedList.join(", ");
  } catch (err) {
    handleError(err);
    return "";
  }
}

export function getMatchingContactGroup(emailArray, currentUser) {
  const emailString = createEmailString(emailArray);
  const contactGroups = getContactGroups({ user: currentUser });
  return contactGroups.find((g) => emailString && emailString === createEmailString(g.emailArray));
}

export async function getDomainAndContacts({
  allLoggedInUsers,
  masterAccount,
  currentUser,
  userEmail,
  searchText,
  searchArray,
}) {
  if (isUserMaestroUser(masterAccount) && isUserDelegatedUser(currentUser)) {
    const { contactResponse, domainResponse } =
      await searchContactAndDomainForUser({
        searchText,
        userEmail,
        searchArray,
      });

    return { contactResponse, domainResponse };
  } else {
    const { contactResponse, domainResponse } =
      await searchContactAndDomainForAllLoggedInUsers({
        allLoggedInUsers,
        searchText,
        userEmail,
        searchArray,
      });

    return { contactResponse, domainResponse };
  }
}

export function escapeRegExp(string) {
  if (!string || !isTypeString(string)) {
    return "";
  }
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/// search for multiple users
async function searchContactAndDomainForAllLoggedInUsers({
  allLoggedInUsers,
  searchText,
  userEmail,
  searchArray,
}) {
  let contactResponse = [];
  let domainResponse = [];

  const searchInputText = searchText?.toLowerCase();
  const searchValue = searchArray ?? searchInputText;

  if (!searchValue || isEmptyArray(allLoggedInUsers)) {
    return { contactResponse, domainResponse };
  }

  let fetchPromises = [];

  if (searchArray) {
    allLoggedInUsers.forEach((user) => {
      const contactPromise = db
        ?.fetch(getUserEmail(user))
        ?.contacts?.where("email")
        .startsWithAnyOfIgnoreCase(searchValue)
        .or("fullName")
        .startsWithAnyOfIgnoreCase(searchValue)
        .or("name")
        .startsWithAnyOfIgnoreCase(searchValue)
        .or("firstName")
        .startsWithAnyOfIgnoreCase(searchValue)
        .or("lastName")
        .startsWithAnyOfIgnoreCase(searchValue)
        .limit(SEARCH_CONTACT_LIMIT)
        .toArray()
        .then((response) => {
          if (isEmptyArray(response)) {
            return;
          }

          const formattedContacts = formatContacts({
            contacts: response,
            userEmail: getUserEmail(user),
          });
          if (equalAfterTrimAndLowerCased(userEmail, getUserEmail(user))) {
            // put matching contact first
            contactResponse = formattedContacts.concat(contactResponse);
          } else {
            contactResponse = contactResponse.concat(formattedContacts);
          }
        })
        .catch((err) => {
          handleError(err);
        });
      fetchPromises = fetchPromises.concat(contactPromise);

      // domain below
      const domainUserPromise = db
        ?.fetch(getUserEmail(user))
        ?.domainUsers?.where("email")
        ?.startsWithAnyOfIgnoreCase(searchValue)
        .or("fullName")
        .startsWithAnyOfIgnoreCase(searchValue)
        .or("name")
        .startsWithAnyOfIgnoreCase(searchValue)
        .limit(SEARCH_CONTACT_LIMIT)
        .toArray()
        .then((response) => {
          if (isEmptyArray(response)) {
            return;
          }

          const formattedContacts = formatContacts({
            contacts: response,
            userEmail: getUserEmail(user),
          });
          if (equalAfterTrimAndLowerCased(userEmail, getUserEmail(user))) {
            domainResponse = formattedContacts.concat(domainResponse);
          } else {
            domainResponse = domainResponse.concat(formattedContacts);
          }
        })
        .catch((err) => {
          handleError(err);
        });

      fetchPromises = fetchPromises.concat(domainUserPromise);
    });
  } else if (searchInputText) {
    // can not use anyOfIgnoreCase because it's suppose to work on arrays of string
    allLoggedInUsers.forEach((user) => {
      const contactPromise = db
        ?.fetch(getUserEmail(user))
        ?.contacts?.filter(function (contact) {
          return (
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.email)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.fullName)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.firstName)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.lastName)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.name)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(removeCommaFromName(contact?.name))
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(flipNameAroundComma(contact?.name))
            )
          );
        })
        .limit(SEARCH_CONTACT_LIMIT)
        .toArray()
        .then((response) => {
          if (isEmptyArray(response)) {
            return;
          }

          const formattedContacts = formatContacts({
            contacts: response,
            userEmail: getUserEmail(user),
          });
          if (equalAfterTrimAndLowerCased(userEmail, getUserEmail(user))) {
            // put matching contact first
            contactResponse = formattedContacts.concat(contactResponse);
          } else {
            contactResponse = contactResponse.concat(formattedContacts);
          }
        })
        .catch((err) => {
          handleError(err);
        });
      fetchPromises = fetchPromises.concat(contactPromise);

      // domain below
      const domainUserPromise = db
        ?.fetch(getUserEmail(user))
        ?.domainUsers?.filter(function (contact) {
          return (
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.email)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.fullName)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(contact?.name)
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(removeCommaFromName(contact?.name))
            ) ||
            createCaseInsensitiveRegex(searchInputText).test(
              convertDBElementToString(flipNameAroundComma(contact?.name))
            )
          );
        })
        .limit(SEARCH_CONTACT_LIMIT)
        .toArray()
        .then((response) => {
          if (isEmptyArray(response)) {
            return;
          }

          const formattedContacts = formatContacts({
            contacts: response,
            userEmail: getUserEmail(user),
          });
          if (equalAfterTrimAndLowerCased(userEmail, getUserEmail(user))) {
            domainResponse = formattedContacts.concat(domainResponse);
          } else {
            domainResponse = domainResponse.concat(formattedContacts);
          }
        })
        .catch((err) => {
          handleError(err);
        });

      fetchPromises = fetchPromises.concat(domainUserPromise);
    });
  }

  return await Promise.all(fetchPromises)
    .then(() => {
      return { contactResponse, domainResponse: sortDomainContacts(domainResponse) };
    })
    .catch((err) => {
      handleError(err);
      return { contactResponse, domainResponse: sortDomainContacts(domainResponse) };
    });
}

export function createCaseInsensitiveRegex(searchString) {
  return new RegExp(escapeRegExp(searchString), "i");
}

/// only search for one user
async function searchContactAndDomainForUser({
  searchText,
  userEmail,
  searchArray,
}) {
  let contactResponse = [];
  let domainResponse = [];

  const searchInputText = searchText?.toLowerCase();
  const searchValue = searchArray ?? searchInputText;

  if (!searchValue || !userEmail) {
    return { contactResponse, domainResponse };
  }

  let contactPromise = [];
  let domainUserPromise = [];
  if (searchArray) {
    contactPromise = db
      ?.fetch(userEmail)
      ?.contacts?.where("email")
      ?.startsWithAnyOfIgnoreCase(searchValue)
      .or("fullName")
      .startsWithAnyOfIgnoreCase(searchValue)
      .or("name")
      .startsWithAnyOfIgnoreCase(searchValue)
      .or("firstName")
      .startsWithAnyOfIgnoreCase(searchValue)
      .or("lastName")
      .startsWithAnyOfIgnoreCase(searchValue)
      .limit(SEARCH_CONTACT_LIMIT)
      .toArray()
      .then((response) => {
        if (response?.length > 0) {
          contactResponse = formatContacts({ contacts: response, userEmail });
        }
      })
      .catch((err) => {
        handleError(err);
      });

    // domain below
    domainUserPromise = db
      ?.fetch(userEmail)
      ?.domainUsers?.where("email")
      .startsWithAnyOfIgnoreCase(searchValue)
      .or("fullName")
      .startsWithAnyOfIgnoreCase(searchValue)
      .or("name")
      .startsWithAnyOfIgnoreCase(searchValue)
      .limit(SEARCH_CONTACT_LIMIT)
      .toArray()
      .then((response) => {
        if (response?.length > 0) {
          domainResponse = formatContacts({ contacts: response, userEmail });
        }
      })
      .catch((err) => {
        handleError(err);
      });
  } else if (searchInputText) {
    contactPromise = db
      ?.fetch(userEmail)
      ?.contacts?.filter(function (contact) {
        return (
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.email)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.fullName)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.firstName)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.lastName)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.name)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(removeCommaFromName(contact?.name))
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(flipNameAroundComma(contact?.name))
          )
        );
      })
      .limit(SEARCH_CONTACT_LIMIT)
      .toArray()
      .then((response) => {
        if (response?.length > 0) {
          contactResponse = formatContacts({ contacts: response, userEmail });
        }
      })
      .catch((err) => {
        handleError(err);
      });

    // domain below
    domainUserPromise = db
      ?.fetch(userEmail)
      ?.domainUsers?.filter(function (contact) {
        return (
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.email)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.fullName)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(contact?.name)
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(removeCommaFromName(contact?.name))
          ) ||
          createCaseInsensitiveRegex(searchInputText).test(
            convertDBElementToString(flipNameAroundComma(contact?.name))
          )
        );
      })
      .limit(SEARCH_CONTACT_LIMIT)
      .toArray()
      .then((response) => {
        if (response?.length > 0) {
          domainResponse = formatContacts({ contacts: response, userEmail });
        }
      })
      .catch((err) => {
        handleError(err);
      });
  }

  return await Promise.all([contactPromise, domainUserPromise])
    .then(() => {
      return { contactResponse, domainResponse: sortDomainContacts(domainResponse) };
    })
    .catch((err) => {
      handleError(err);
      return { contactResponse, domainResponse: sortDomainContacts(domainResponse) };
    });
}

function sortDomainContacts(domainResponse) {
  if (isEmptyArray(domainResponse)) {
    return [];
  }
  // domainResponse = [dcontact]
  // contact below:
  // {
  //   "email": "john@vimcal.com",
  //   "firstName": "John",
  //   "lastName": "Li",
  //   "name": "John Li",
  //   "fullName": [
  //       "John",
  //       "Li",
  //       "John Li",
  //       "john@vimcal.com",
  //       "com",
  //       "vimcal.com"
  //   ],
  //   "primary": true,
  //   "accountPrimaryEmail": "john@vimcal.com",
  //   "userEmail": "mike@vimcal.com"
  // }
  const sorted = immutablySortArray(domainResponse, (a, b) => {
    const aEmail = a.email;
    const bEmail = b.email;
    const aIsGeneric = isEmailGenericEmail(aEmail);
    const bIsGeneric = isEmailGenericEmail(bEmail);

    if (!aIsGeneric && bIsGeneric) {
      return -1;
    }
    if (aIsGeneric && !bIsGeneric) {
      return 1;
    }
    return 0;
  });

  return sorted;
}

export function isEmailGenericEmail(email) {
  if (!email) {
    return false;
  }
  const domain = email.split("@")?.[1]; // Get the domain part of the email
  return EXCLUDED_DOMAINS.includes(domain);
}

function formatContacts({ contacts, userEmail }) {
  if (isEmptyArray(contacts)) {
    return [];
  }

  let updatedContacts = [];
  contacts.forEach((c) => {
    if (!c.userEmail) {
      c.userEmail = userEmail;
    }

    updatedContacts = updatedContacts.concat(c);
  });

  return updatedContacts;
}

export function getRecentContactsForUpsert({ user }) {
  if (!getUserEmail(user)) {
    return [];
  }

  return getRecentContacts(user) ?? [];
}

export function convertDBElementToString(element) {
  try {
    if (!element) {
      return "";
    }
  
    if (isTypeString(element)) {
      return element;
    }
  
    if (element.join) {
      return element.join(" ");
    }
  
    return "";
  } catch (err) {
    return "";
  }
}

export function getNameFromContact(contact) {
  if (isEmptyObjectOrFalsey(contact)) {
    return "";
  }

  if (contact.name) {
    return contact.name;
  }

  if (contact.first_name && contact.last_name) {
    return `${contact.first_name} ${contact.last_name}`;
  }

  if (contact.first_name) {
    return contact.first_name;
  }

  if (contact.last_name) {
    return contact.last_name;
  }

  return "";
}

export function sortContactsArrayByExactMatch(array, searchString) {
  if (isEmptyArray(array)) {
    return [];
  }
  const lowerCaseSearchString = lowerCaseAndTrimString(searchString);
  if (!lowerCaseSearchString) {
    return array;
  }

  const getValue = (a) => {
    if (!a) {
      return "";
    }
    const {
      name,
      fullName,
      email
    } = a;
    if (name && isTypeString(name)) {
      return name;
    }
    if (fullName && isTypeString(fullName)) {
      return fullName;
    }
    return email ?? "";
  };

  const isMatchingName = (a) => {
    const lowerCaseName = lowerCaseAndTrimString(getValue(a));
    const lowerCaseEmail = lowerCaseAndTrimString(a.email);
    if (lowerCaseName === lowerCaseSearchString) {
      return true;
    }
    if (lowerCaseName?.startsWith(lowerCaseSearchString)) {
      // if first name includes
      return true;
    }
    if (lowerCaseEmail?.startsWith(lowerCaseSearchString)) {
      return true;
    }
    // last name includes
    return lowerCaseName?.endsWith(" " + lowerCaseSearchString)
  };

  // since .sort is mutable
  return immutablySortArray(array, (a, b) => {
    if (
      isMatchingName(a) &&
      !isMatchingName(b)
    ) {
      return -1; // a is an exact match, b is not, a comes first
    } else if (
      isMatchingName(b) &&
      !isMatchingName(a)
    ) {
      return 1; // b is an exact match, a is not, b comes first
    }
    return 0; // Neither is an exact match, or both are, retain original order
  });
}

function isValidContact(contact) {
  if (!contact) {
    return false;
  }
  if (contact.hasMultiple) {
    return true;
  }
  const email = getObjectEmail(contact);
  if (!email) {
    return false;
  }
  return isValidEmail(email);
}

export function filterOutInvalidContacts(contacts) {
  if (isEmptyArray(contacts)) {
    return [];
  }

  // filter out contacts with invalid emails
  // filter out duplicate contacts
  // be careful now to filter out multiple
  const filteredContacts = contacts.filter((c) => isValidContact(c));
  const uniqueContacts = filteredContacts.filter((item, index, array) => 
    array.findIndex(obj => getCombinedEmailName(obj) === getCombinedEmailName(item)) === index
  );

  return uniqueContacts;
}

function getCombinedEmailName(contact) {
  if (isEmptyObjectOrFalsey(contact)) {
    return "";
  }
  const email = getObjectEmail(contact);
  if (!email) {
    return "";
  }
  if (contact?.name) {
    return `${contact.name} <${email}>`;
  }
  if (isTypeString(contact.fullName)) {
    return `${contact.fullName} <${email}>`;
  }
  if (isTypeString(contact.full_name)) {
    return `${contact.full_name} <${email}>`;
  }

  return email;
}

function removeCommaFromName(name) {
  try {
    if (!name) {
      return "";
    }
    return name.replace(",", "");
  } catch (err) {
    return name || "";
  }
}

function flipNameAroundComma(name) {
  try {
    if (!name) {
      return "";
    }
    if (!name.includes(",")) {
      return name;
    }
    const nameArray = name.split(",");
    if (nameArray.length !== 2) {
      return name;
    }
    return `${nameArray[1]} ${nameArray[0]}`;
  } catch (err) {
    return name || "";
  }
}
