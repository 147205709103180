import create from "zustand";
import { persist } from "zustand/middleware";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { storeAllLoggedInUsers, storeCalendarSelectedOverridePerUser, storeMasterAccount } from "../../lib/stateManagementFunctions";

export interface StoredCalendar {
  calendar: VimcalCalendar
  selected: boolean
  userEmail: string
  isFetching?: boolean
  lastSyncedAt?: string
}

export interface AllCalendarsState {
  allCalendars: Partial<Record<string, StoredCalendar>>
  setAllCalendars: (allCalendars: Partial<Record<string, StoredCalendar>>, currentUser: User, fromWhere: unknown) => void
  removeCalendar: (userCalendarID: string, user: User) => void
  resetAllCalendars: () => void
  setCalendar: (options: {
    userCalendarID: string
    updatedCalendar: Partial<StoredCalendar>
    currentUser: User
  }) => void
}

export const useAllCalendars = create(
  // keeps track of every calendar in an index
  persist<AllCalendarsState>(
    (set, get) => ({
      allCalendars: {},
      setAllCalendars: (allCalendars, currentUser, _fromWhere) => {
        // console.log("setCalendars_fromWhere_", {_fromWhere, currentUser, allCalendars});
        if (!allCalendars) {
          return;
        }
        storeCalendarSelectedOverridePerUser(allCalendars, currentUser);
        set(() => ({
          allCalendars,
        }));
      },
      removeCalendar: (userCalendarID, user) => {
        const { [userCalendarID]: _omitted, ...rest } = get().allCalendars;
        const updatedAllCalendars = rest;
        storeCalendarSelectedOverridePerUser(updatedAllCalendars, user);

        set(() => ({
          allCalendars: updatedAllCalendars,
        }));
      },
      resetAllCalendars: () =>
        set(() => ({ allCalendars: {} })),
      setCalendar: ({userCalendarID, updatedCalendar, currentUser}) => {
        /* Sets a single calendar to prevent stale state when doing parallel fetches */
        const currentAllCalendars = get().allCalendars;
        const updatedAllCalendars = {
          ...currentAllCalendars,
          [userCalendarID]: {
            ...currentAllCalendars[userCalendarID],
            ...updatedCalendar,
          } as StoredCalendar,
        };
        storeCalendarSelectedOverridePerUser(updatedAllCalendars, currentUser);

        set(() => ({
          allCalendars: updatedAllCalendars,
        }));
      },
    }),
    {
      name: "all-calendars-storage", // unique name
    },
  ),
);

interface AllLoggedInUsersState {
  allLoggedInUsers: User[]
  setAllLoggedInUsers: (users: User[]) => void
  resetAllLoggedInUsers: () => void
  collapsedUserCalendarList: string[]
  setCollapsedUserCalendarList: (emails: string[]) => void
  resetCollapsedUserCalendarList: () => void
}

export const useAllLoggedInUsers = create(
  // keeps track of every calendar in an index
  persist<AllLoggedInUsersState>(
    (set) => ({
      allLoggedInUsers: [],
      setAllLoggedInUsers: (allLoggedInUsers) => {
        mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
        storeAllLoggedInUsers(allLoggedInUsers);
        set(() => ({ allLoggedInUsers }));
      },
      resetAllLoggedInUsers: () => set(() => ({ allLoggedInUsers: [] })),

      collapsedUserCalendarList: [],
      setCollapsedUserCalendarList: (collapsedUserCalendarList) => {
        set(() => ({ collapsedUserCalendarList }));
      },
      resetCollapsedUserCalendarList: () =>
        set(() => ({ collapsedUserCalendarList: [] })),
    }),
    {
      name: "all-logged-in-users-storage", // unique name
    },
  ),
);

export interface AllUserDomainsState {
  allUserDomains: Partial<Record<string, Domain>>
  setAllUserDomains: (allUserDomains: Partial<Record<string, Domain>>) => void
  resetAllUserDomains: () => void
}

export const useAllUserDomains = create(
  // keeps track of domain info for every logged in user
  // load from response on login {user, domain, availability_settings} = response
  // {id: 1, name: "vimcal.com"}
  persist<AllUserDomainsState>(
    (set) => ({
      allUserDomains: {},
      setAllUserDomains: (allUserDomains) => {
        set(() => ({ allUserDomains }));
      },
      resetAllUserDomains: () => set(() => ({ allUserDomains: {} })),
    }),
    {
      name: "all-user-domains-storage", // unique name
    },
  ),
);

interface MasterAccountState {
  masterAccount: MasterAccount | Record<string, never>
  setMasterAccount: (masterAccount: MasterAccount) => void
  setMasterAccountCompletedTooltips: (completedTooltips: string[]) => void
  setMasterAccountSettings: (settings: MasterAccountSettings) => void
  resetMasterAccount: () => void
}

export const useMasterAccount = create(
  // master account data that's shared across all users
  persist<MasterAccountState>(
    (set) => ({
      masterAccount: {},
      setMasterAccount: (masterAccount) => {
        storeMasterAccount(masterAccount); // save in local storage
        set(() => ({ masterAccount }));
      },
      setMasterAccountCompletedTooltips: (completedTooltips) => {
        set((state) => ({
          masterAccount: {
            ...state.masterAccount as MasterAccount,
            completed_tooltips: completedTooltips,
          },
        }));
      },
      setMasterAccountSettings: (masterAccountSettings) => {
        set((state) => ({
          masterAccount: {
            ...state.masterAccount as MasterAccount,
            settings: masterAccountSettings,
          },
        }));
      },
      resetMasterAccount: () => {
        set(() => ({ masterAccount: {} }));
      },
    }),
    {
      name: "master-account-storage", // unique name
    },
  ),
);

interface StoredScheduler {
  email: string
  host_email: string
  error?: string
  schedulers: {
    email: string
    id: string
    pmi: number
  }[]
  token: string
  user_id: number
}

export interface ZoomSchedulersState {
  schedulers: Record<number, StoredScheduler>
  setSchedulers: (schedulers: Record<number, StoredScheduler>) => void
  resetSchedulers: () => void
}

// Schedulers in this context are the users for whom the current user can schedule meetings for
export const useZoomSchedulers = create(
  // Zoom emails that current user can schedule for
  persist<ZoomSchedulersState>(
    (set) => ({
      schedulers: {},
      setSchedulers: (schedulers) => {
        set(() => ({ schedulers }));
      },
      resetSchedulers: () => set(() => ({ schedulers: {} })),
    }),
    {
      name: "master-account-zoom-scheduler-for-storage", // unique name
    },
  ),
);
