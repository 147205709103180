type Falsey = false | "" | 0 | null | undefined | void

export function isTypeNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isTypeString(value: unknown): value is string {
  return typeof value === "string";
}

/**
 * Note that this function explicitly checks that the value is an array.
 * `null`, `undefined`, and other falsey values will return false here
 * since they are not arrays.
 */
export function isEmptyArray(value: unknown): value is [] {
  return Array.isArray(value) && value.length === 0;
}

export function isEmptyArrayOrFalsey(value: unknown): value is [] | Falsey {
  return !value || isEmptyArray(value);
}

/**
 * Use caution with this type guard. This does not strictly check that the value
 * is an object of key/value pairs. Other data types also have a type of `object`
 * and will return `true` here. This includes:
 * - `null`
 * - Arrays
 * - `Date` instances
 * - `RegExp` instances
 *
 * Use `isDictionary` if you want to check if the value is a standard key-value object.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isTypeObject(value: unknown): value is object | null {
  return typeof value === "object";
}

type Dictionary = Record<string | number | symbol, unknown>;

/**
 * Check that the value is a dictionary of key-value pairs. This excludes
 * types that are considered objects in JS, such as `null`, `Date`, and `RegExp`.
 */
export function isDictionary(value: unknown[]): value is never;
export function isDictionary(value: boolean): value is never;
export function isDictionary(value: null): value is never;
export function isDictionary(value: number): value is never;
export function isDictionary(value: string): value is never;
export function isDictionary(value: undefined): value is never;
export function isDictionary(value: Date): value is never;
export function isDictionary(value: RegExp): value is never;
export function isDictionary(value: Dictionary): value is Dictionary;
export function isDictionary(value: unknown): value is Dictionary;
export function isDictionary(value: unknown): value is Dictionary {
  if (!value) {
    return false;
  }

  if (Array.isArray(value)) {
    return false;
  }

  if (typeof value !== "object") {
    return false;
  }

  if (value instanceof RegExp) {
    return false;
  }

  if (value instanceof Date) {
    return false;
  }

  return true;
}

function isEmptyObject(value: unknown): value is Record<string, never> {
  if (!value || !isDictionary(value)) {
    return false;
  }

  return Object.keys(value).length === 0;
}

export function isEmptyObjectOrFalsey(value: unknown): value is Record<string, never> | Falsey {
  return !value || isEmptyObject(value);
}

export function isUndefined(value: unknown): value is undefined {
  return typeof value === "undefined";
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isNullOrUndefined(value: unknown): value is null | undefined {
  return isNull(value) || isUndefined(value);
}
